<div class="d-flex flex-column box0">
  <div class="sidenav-title">{{ title }}</div>
  Testing
  <app-flex-dollars-summary class="mt-3"
    override="1"
    [flexDollarsAvailable]="flexDollarsAvailable"
    [flexDollarsEarned]="flexDollarsEarned"
    [flexDollarsRedeemed]="flexDollarsRedeemed"
  >
  </app-flex-dollars-summary>
  <div class="d-flex flex-column mt-3">
    <div class="text6">{{ header }}</div>
    <div class="d-flex flex-row align-items-center mt-3 box4">
      <div
        class="p-2 cursor-pointer"
        [ngClass]="periodClass('overall')"
        (click)="onChangeTransactionsTab('overall')"
      >
        {{ 'common.overall' | translate }}
      </div>
      <div
        class="mx-2 p-2 cursor-pointer"
        [ngClass]="periodClass('year')"
        (click)="onChangeTransactionsTab('year')"
      >
        {{ 'common.yearly' | translate }}
      </div>
      <div
        class="p-2 cursor-pointer"
        [ngClass]="periodClass('month')"
        (click)="onChangeTransactionsTab('month')"
      >
        {{ 'common.monthly' | translate }}
      </div>
    </div>
  </div>
  <app-progress-bar *ngIf="loading" style="margin-top: 12px"></app-progress-bar>
  <div class="d-flex flex-column point-list" *ngIf="!showPointCategories && transactions">
    <div
      class="d-flex flex-row bd-highlight align-items-center box7"
      *ngFor="let transaction of transactions; trackBy: trackByPointCategory"
    >
      <ng-container *ngIf="!transaction.icon && !transaction.reward_image_url">
        <div *ngIf="!!acronym(transaction)" class="txt--acronym">{{ acronym(transaction) }}</div>
        <img *ngIf="!acronym(transaction)" class="bd-highlight" src="assets/img/default-user.png" width="36px" height="36px" />
      </ng-container>
      <img
        *ngIf="!!transaction.icon || !!transaction.reward_image_url"
        class="bd-highlight"
        [src]="icon(transaction)"
        width="36px"
        height="36px"
      />
      <div
        class="bd-highlight ms-3 text9"
        [style.cursor]="cursor(transaction)"
        (click)="open(transaction)"
      >
        {{ getTransactionName(transaction) }}
      </div>
      <div
        class="bd-highlight ms-auto text10"
        [ngStyle]="getColor(transaction)"
      >
        {{ transaction?.points }}
      </div>
    </div>
  </div>
  <div class="d-flex flex-column point-list" *ngIf="showPointCategories && !!pointCategories">
    <div
      class="d-flex flex-row bd-highlight align-items-center box7"
      *ngFor="let pc of pointCategories; trackBy: trackByPointCategory"
    >
      <div
        *ngIf="!pc.icon"
        class="txt--acronym"
      >
        {{ acronym2(pc) }}
      </div>
      <img
        *ngIf="!!pc?.icon"
        class="bd-highlight"
        [src]="icon2(pc)"
        width="36px"
        height="36px"
      />
      <div class="bd-highlight ms-3 text9">
        {{ pc.name }}
      </div>
      <div class="bd-highlight ms-auto text10">
        {{ pc?.points }}
      </div>
    </div>
  </div>
  <div style="margin-top: 12px">
    <div class="btn-drawer" [routerLink]="['/point-history']" [queryParams]="{point_type: 'all', point_category: 'all'}">{{ btnLabel }}</div>
  </div>
</div>